import styled, { css } from 'styled-components';

// Import styles
import { rtl } from 'components/styles';

export const ListItem = styled.div`
	${rtl`
    direction: ltr;
	margin-right: 0.8rem;
  `}
`;

export const Wrapper = styled.div`
	height: calc(100% - 12rem);
	width: 100%;
	padding: 0.8rem 0;

	.player-sidebar-list {
		/* Firefox */
		scrollbar-width: thin;
		${({ theme }) => css`
			scrollbar-color: ${theme.playerTv.sidebar.scrollbar.thumb}
				${theme.playerTv.sidebar.scrollbar.track};
		`};
	}

	/* WebKit, Chrome, Edge, Safari */
	.player-sidebar-list::-webkit-scrollbar {
		width: 0.5rem;
	}

	.player-sidebar-list::-webkit-scrollbar-thumb {
		background-color: ${({ theme }) => theme.playerTv.sidebar.scrollbar.thumb};
		border-radius: 1rem;
	}

	.player-sidebar-list::-webkit-scrollbar-track {
		background: ${({ theme }) => theme.playerTv.sidebar.scrollbar.track};
		border-radius: 1rem;
	}
`;
