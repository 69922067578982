import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { deleteFavourite, addFavourite } from 'store/actions';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useTranslation } from 'react-i18next';

// Import assets
import { ReactComponent as AddingSuccessIcon } from 'assets/icons/ic_wachlist_success_adding.svg';

// Import helpers
import { checkIsFavourite } from 'helpers/epg';
import { openNotification } from 'components/utilities/notification/notification';

// Import icons
import ActiveWatchlistIconSVG from 'assets/icons/ic_watchlist_active.svg';
import InactiveWatchlistIconSVG from 'assets/icons/ic_watchlist_inactive.svg';

// Import components
import { ButtonStyled } from './styles';

// Import hooks
import { usePlayerSidebarContext } from 'components/context/player/usePlayerSidebarContext';

const FavouriteButton = ({ channelID }) => {
	const { t } = useTranslation();
	const { showModal, isModalAvailable } = usePlayerSidebarContext();

	const dispatch = useDispatch();
	const favourites = useSelector((store) => store.favourites.data);

	const isFavourite = checkIsFavourite(favourites, channelID);

	const handleDeleteFavourite = useCallback(
		(e) => {
			e.stopPropagation();
			deleteFavourite(channelID)(dispatch);
			openNotification({
				type: 'success',
				title: t('player_tv_watchlist_removing'),
				icon: <AddingSuccessIcon />,
				closeIcon: <div></div>,
				className: 'player_tv_notification'
			});
		},
		[channelID, dispatch, t]
	);

	const handleAddFavourite = useCallback(
		(e) => {
			e.stopPropagation();
			addFavourite(channelID)(dispatch);
			!isModalAvailable &&
				openNotification({
					type: 'success',
					title: t('player_tv_watchlist_adding'),
					icon: <AddingSuccessIcon />,
					closeIcon: <div></div>,
					className: 'player_tv_notification'
				});

			showModal();
		},
		[channelID, dispatch, showModal, isModalAvailable, t]
	);

	return isFavourite ? (
		<ButtonStyled onClick={handleDeleteFavourite} isFavourite>
			<LazyLoadImage src={ActiveWatchlistIconSVG} width={35} />
		</ButtonStyled>
	) : (
		<ButtonStyled onClick={handleAddFavourite}>
			<LazyLoadImage src={InactiveWatchlistIconSVG} width={35} />
		</ButtonStyled>
	);
};

FavouriteButton.propTypes = {
	channelID: PropTypes.string.isRequired
};

export default FavouriteButton;
