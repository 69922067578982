import React from 'react';
import PropTypes from 'prop-types';

// Import Provider
import PlayerSidebarProvider, {
	PlayerSidebarContext
} from 'components/context/player/PlayerSidebarProvider';

// Import components
import { SidebarWatchlistModal } from './sidebar_watchlist_guideline/SidebarWatchlistModal';
import useUpdateCurrentProgram from 'components/utilities/hooks/program/useUpdateCurrentProgram';
import SidebarNav from './SidebarNav';
import SidebarChannels from './sidebar_channels/SidebarChannels';
import { Sidebar } from './styles';

const PlayerSidebar = ({ isFocus, isFullScreen }) => {
	useUpdateCurrentProgram();

	return (
		<PlayerSidebarProvider>
			<PlayerSidebarContext.Consumer>
				{({ query, category, setCategory }) => (
					<Sidebar isFocus={isFocus} isFullScreen={isFullScreen}>
						{!isFullScreen && (
							<SidebarNav
								category={category}
								setCategory={setCategory}
								isFullScreen={isFullScreen}
							/>
						)}
						<SidebarChannels
							isFullScreen={isFullScreen}
							query={query}
							category={category}
						/>
						<SidebarWatchlistModal />
					</Sidebar>
				)}
			</PlayerSidebarContext.Consumer>
		</PlayerSidebarProvider>
	);
};

PlayerSidebar.propTypes = {
	isFocus: PropTypes.bool.isRequired,
	isFullScreen: PropTypes.bool.isRequired
};

export default PlayerSidebar;
