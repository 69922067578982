import styled, { css } from 'styled-components';

// Import utilities
import { flex, rtl } from 'components/styles';

export const Sidebar = styled.div`
	${flex({ fd: 'column' })};
	position: absolute;
	top: ${({ isFullScreen }) => (isFullScreen ? '0.42rem' : '0')};
	bottom: 0;
	height: ${({ theme, isFullScreen }) =>
		theme.playerTv.sidebar.height[isFullScreen ? 'fullscreen' : 'default']};
	width: ${({ theme: { playerTv } }) => playerTv.sidebar.width}rem;
	background: ${({ theme, isFullScreen }) =>
		theme.playerTv.sidebar.background[isFullScreen ? 'fullscreen' : 'default']};
	opacity: 1;
	z-index: 999;
	${rtl`
    direction: ltr;
    right: 0;
	padding: 2.4rem 0.8rem 2.4rem 1.6rem;
  `}

	opacity: ${({ isFocus, isFullScreen }) =>
		isFullScreen && !isFocus ? '0' : '1'};


	${({ isFocus }) =>
		isFocus &&
		css`
			opacity: 1;
		`};

	&:hover {
		transition: opacity 0.3s ease-in 0s;
		opacity: 1;
	}

	.ReactVirtualized__List {
		overflow: scroll !important;
		overflow-x: hidden !important;
	}
`;

export const SidebarNavStyled = styled.div`
	width: 100%;
	margin: 0;
	position: relative;
`;
// FIXME:filters
export const List = styled.ul`
	${flex({ jc: 'space-between' })};
	padding: 0.8rem 0rem;
	gap: 0.8rem;
	list-style: none;
	overflow-x: auto;
	border-radius: 0.6rem;
	opacity: 1;
	visibility: visible;

	&::-webkit-scrollbar {
		height: 0.5rem;
		background-color: ${({ theme }) => theme.playerTv.sidebar.scrollbar.track};
		border-radius: 1rem;
	}

	&::-webkit-scrollbar-thumb {
		background-color: ${({ theme }) => theme.playerTv.sidebar.scrollbar.track};
		border-radius: 1rem;
	}

	&::-webkit-scrollbar-track {
		background-color: ${({ theme }) => theme.playerTv.sidebar.scrollbar.track};
	}

	&:hover {
		&::-webkit-scrollbar {
			height: 0.5rem;
			background-color: ${({ theme }) =>
				theme.playerTv.sidebar.scrollbar.track};
			border-radius: 1rem;
		}

		&::-webkit-scrollbar-thumb {
			background-color: ${({ theme }) =>
				theme.playerTv.sidebar.scrollbar.thumb};
			border-radius: 1rem;
		}
	}

	${({ isVisible }) =>
		isVisible &&
		css`
			opacity: 0;
			visibility: hidden;
		`}
`;

export const Item = styled.li`
	font-size: 1.5rem;
	display: block;
	height: auto !important;
	color: ${({ theme }) => theme.playerTv.sidebar.filters.color};
`;

export const ItemNav = styled.span`
	display: block;
	font-size: 1.4rem;
	cursor: pointer;
	border-radius: 0.6rem;
	padding: 0.65rem 1rem;
	transition: background 0.3s ease-in;
	white-space: nowrap;
	background: ${({ theme, isActive }) =>
		isActive
			? theme.playerTv.sidebar.filters.background.active
			: theme.playerTv.sidebar.filters.background.default};
`;
