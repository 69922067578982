import styled from 'styled-components';

// Import styles
import { flex } from 'components/styles';

export const CheckboxWrapper = styled.label`
	width: 100%;
	cursor: pointer;
	display: flex;
	gap: 1rem;
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
	opacity: 0;
	position: absolute;
	pointer-events: none;
`;

export const StyledCheckbox = styled.div`
	width: 2rem;
	height: 2rem;
	${flex()};
	color: ${({ theme: { playerTv } }) =>
		playerTv.sidebar.watchlist_modal.checkbox.icon_checked};
	border: 1px solid
		${({ theme: { playerTv } }) =>
			playerTv.sidebar.watchlist_modal.checkbox.border.default};
	background-color: ${({ theme: { playerTv } }) =>
		playerTv.sidebar.watchlist_modal.checkbox.bg.default};
	border-radius: 0.4rem;
	transition: all 0.2s ease;

	${HiddenCheckbox}:checked + & {
		background-color: ${({ theme: { playerTv } }) =>
			playerTv.sidebar.watchlist_modal.checkbox.bg.active};
		border-color: ${({ theme: { playerTv } }) =>
			playerTv.sidebar.watchlist_modal.checkbox.border.active};
	}

	svg {
		visibility: hidden;
	}

	${HiddenCheckbox}:checked + & svg {
		visibility: visible;
	}
`;

export const Label = styled.span`
	font-size: 1.2rem;
	font-size: ${({ theme }) => theme.font.normal};
	color: ${({ theme: { playerTv } }) =>
		playerTv.sidebar.watchlist_modal.checkbox.label};
`;
