import React from 'react';
import { string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Import redux actions
import { addFavourite, deleteFavourite } from 'store/actions';

// Import assets
import ActiveWatchlistIconSVG from 'assets/icons/ic_watchlist_active.svg';
import InactiveWatchlistIconSVG from 'assets/icons/ic_watchlist_inactive.svg';

// Import components
import { AuthModal } from 'components/elements';
import { ButtonStyled } from './styles';

// Import helpers
import { checkIsFavourite } from 'helpers/epg';

export function FavouriteButton({ uuid }) {
	const dispatch = useDispatch();
	const favourites = useSelector(({ favourites }) => favourites.data);

	const isFavourite = checkIsFavourite(favourites, uuid);

	const handleButtonClick = () =>
		isFavourite
			? deleteFavourite(uuid)(dispatch)
			: addFavourite(uuid)(dispatch);

	return (
		<AuthModal>
			<ButtonStyled isFavourite={isFavourite} onClick={handleButtonClick}>
				{isFavourite ? (
					<LazyLoadImage src={ActiveWatchlistIconSVG} width={60} />
				) : (
					<LazyLoadImage src={InactiveWatchlistIconSVG} width={60} />
				)}
			</ButtonStyled>
		</AuthModal>
	);
}

FavouriteButton.propTypes = {
	uuid: string.isRequired
};
