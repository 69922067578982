import React from 'react';
import styled, { keyframes, css } from 'styled-components';

export const SkeletonSidebarChannelList = () => {
	const numberOfSkeletons = 15;
	return (
		<SkeletonContainer>
			{Array.from({ length: numberOfSkeletons }).map((_, index) => (
				<SkeletonChannel key={index}>
					<SkeletonFavouriteBox />
					<SkeletonLogoBox />
					<SkeletonDescriptionBox />
				</SkeletonChannel>
			))}
		</SkeletonContainer>
	);
};

const shimmerStyle = css`
	${({ theme }) => css`
		background: ${theme.playerTv.sidebar.shimmer.background};
		background-size: ${theme.playerTv.sidebar.shimmer.backgroundSize};
		animation: ${keyframes`${theme.playerTv.sidebar.shimmer.keyframes}`}
			${theme.playerTv.sidebar.shimmer.animation};
	`};
`;

export const SkeletonContainer = styled.div`
	height: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	gap: 0.8rem;
`;

export const SkeletonChannel = styled.div`
	display: flex;
	gap: 0.8rem;
	width: calc(100% - 0.8rem);
	height: 6.7rem;
`;

export const SkeletonFavouriteBox = styled.div`
	width: 3.7rem;
	height: 6.7rem;
	flex-shrink: 0;
	border-radius: 0.4rem;
`;

export const SkeletonLogoBox = styled.div`
	width: 6.7rem;
	height: 6.7rem;
	border-radius: 0.4rem;
	${shimmerStyle}
`;

export const SkeletonDescriptionBox = styled.div`
	width: 24rem;
	height: 6.7rem;
	border-radius: 0.4rem;
	${shimmerStyle}
`;
